// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";

import getCurrentDateInBritain from "../utils/getCurrentDateinBritain";
import firestoreKey from "../auth.json";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
if (!firestoreKey) {
  throw new Error("Firestore key not found");
}
const app = initializeApp(firestoreKey);
const db = getFirestore(app);
const analytics = getAnalytics(app);

async function getSong(songName) {
  const songsQuery = query(
    collection(db, "songs"),
    where("name", "==", songName)
  );
  const songsQuerySnapshot = await getDocs(songsQuery);
  if (!songsQuerySnapshot.empty) {
    const song = songsQuerySnapshot.docs[0]; // returns undefined if successCount is not found
    return song;
  } else {
    return null;
  }
}

async function getDailyChallenge() {
  const formattedDate = getCurrentDateInBritain();
  const dailyChallengesRef = doc(db, "dailyChallenges", formattedDate);
  const dailyChallengesSnap = await getDoc(dailyChallengesRef);
  return dailyChallengesSnap.data();
}

async function getDailyChallengePercentileAndIncrement(result) {
  const dailyChallenge = await getDailyChallenge();
  const dailyChallengeResults = dailyChallenge?.results ?? [];

  if (result) {
    const dailyChallengeSubmissions = dailyChallenge?.submissions
      ? dailyChallenge.submissions + 1
      : 1;
    dailyChallengeResults.push(result);
    await setDoc(
      doc(db, "dailyChallenges", dailyChallenge.date),
      {
        submissions: dailyChallengeSubmissions,
        results: dailyChallengeResults,
      },
      { merge: true }
    );
  }

  const sortedResults = dailyChallengeResults.sort((a, b) => a - b);
  const resultIndex = sortedResults.findIndex((value) => value >= result);
  const percentileOpposite = (resultIndex / sortedResults.length) * 100;
  const percentile = 100 - percentileOpposite;
  return percentile;
}

export async function getDailyChallengeResults() {
  const dailyChallenge = await getDailyChallenge();
  const dailyChallengeResults = dailyChallenge?.results ?? [];
  console.log('results' + dailyChallengeResults)
  return dailyChallengeResults;
}
export function calculateDailyChallengePercentile(dailyChallengeResults, result) {
  const sortedResults = dailyChallengeResults.sort((a, b) => a - b);
  const resultIndex = sortedResults.findIndex((value) => value >= result);
  const percentileOpposite = (resultIndex / sortedResults.length) * 100;
  const percentile = 100 - percentileOpposite;
  return percentile;
}
export async function incrementDailyChallenge(result) {
  const dailyChallenge = await getDailyChallenge();
  const dailyChallengeResults = dailyChallenge?.results ?? [];

  if (result) {
    const dailyChallengeSubmissions = dailyChallenge?.submissions
      ? dailyChallenge.submissions + 1
      : 1;
    dailyChallengeResults.push(result);
    await setDoc(
      doc(db, "dailyChallenges", dailyChallenge.date),
      {
        submissions: dailyChallengeSubmissions,
        results: dailyChallengeResults,
      },
      { merge: true }
    );
  }
}

async function getStatistics() {
  const statisticsRef = doc(db, "statistics", "global");
  const statisticsSnap = await getDoc(statisticsRef);
  return statisticsSnap.data();
}

async function incrementGlobalGuessCounter() {
  const statistics = await getStatistics();
  await setDoc(doc(db, "statistics", "global"), {
    guesses: statistics.guesses + 1,
  });
}

async function incrementSongSuccessCount(songName) {
  const songSnapshot = await getSong(songName);
  const song = songSnapshot.data();
  const successCount = song?.successCount ?? 0;
  const successRate = calculateSuccessRate(song);
  if (successRate) {
    await setDoc(
      doc(db, "songs", songName),
      {
        successCount: successCount + 1,
        name: songName,
        successRate: calculateSuccessRate(song, 1),
        dateUpdated: new Date(),
      },
      { merge: true }
    );
  } else {
    await setDoc(
      doc(db, "songs", songName),
      {
        successCount: successCount,
        name: songName,
        dateUpdated: new Date(),
      },
      { merge: true }
    );
  }
}

async function incrementSongFailureCount(songName) {
  const songSnapshot = await getSong(songName);
  const song = songSnapshot.data();
  const failureCount = song?.failureCount ?? 0;
  const successRate = calculateSuccessRate(song, 0);
  if (successRate) {
    await setDoc(
      doc(db, "songs", songName),
      {
        failureCount: failureCount + 1,
        name: songName,
        successRate: calculateSuccessRate(song),
        dateUpdated: new Date(),
      },
      { merge: true }
    );
  } else {
    await setDoc(
      doc(db, "songs", songName),
      {
        failureCount: failureCount,
        name: songName,
        dateUpdated: new Date(),
      },
      { merge: true }
    );
  }
}

function calculateSuccessRate(song, successStatus) {
  if (!song) return 0;
  let successRate = 0;
  const successCount = song?.successCount ?? 0;
  const failureCount = song?.failureCount ?? 0;
  if (successStatus == 1) {
    successRate = successCount+1 / (successCount + failureCount + 1);
  } else {
    successRate = successCount / (successCount + failureCount + 1);
  }
  
  console.log(`Success Rate average: ${successRate}`);
  return successRate ?? null;
}

export {
  getDailyChallenge,
  getDailyChallengePercentileAndIncrement,
  getSong,
  getStatistics,
  incrementGlobalGuessCounter,
  incrementSongFailureCount,
  incrementSongSuccessCount,
};
